<template>
  <div class="page-register">
    <div class="app-logo">
      <img class="logo" src="../../assets/images/logo-white.png" />
    </div>
    <!-- 分步 -->
    <div class="app-progress">
      <div class="progress-item blue">1</div>
      <div class="progress-line"></div>
      <div class="progress-item">2</div>
    </div>
    <!-- 表单 -->
    <div class="app-form" v-if="langInfo">
      <!--Name-->
      <div class="form-item">
        <div class="item-title">
          <img class="icon" src="../../assets/images/icons/user.png" />
          <div class="text">
            {{ langInfo.newLangInfo.registerPage.name }}
          </div>
        </div>
        <div class="item-value">
          <input v-model="formData.Name" @focus="nameTips = ''" @blur="nameRegex" class="value-input" :placeholder="langInfo.newLangInfo.registerPage.mandatory" maxlength="100" />
        </div>
        <div class="item-tips">
          {{ nameTips }}
        </div>
      </div>
      <!--Email-->
      <div class="form-item">
        <div class="item-title">
          <img class="icon" style="width: 18px; height: 15px" src="../../assets/images/icons/email.png" />
          <div class="text">
            {{ langInfo.newLangInfo.registerPage.email }}
          </div>
        </div>
        <div class="item-value">
          <input v-model="formData.Email" @focus="emailTips = ''" @blur="emailRegex" class="value-input" :placeholder="langInfo.newLangInfo.registerPage.mandatory" />
        </div>
        <div class="item-tips">
          {{ emailTips }}
        </div>
      </div>
      <!--Phone Number-->
      <div class="form-item">
        <div class="item-title">
          <img class="icon" src="../../assets/images/icons/phone.png" />
          <div class="text">
            {{ langInfo.newLangInfo.registerPage.phone }}
          </div>
        </div>
        <div class="item-value flex">
          <span class="tips">+{{ showDataInfo.AreaCode }}</span>
          <input v-model="formData.Phone" @focus="phoneTips = ''" @blur="phoneRegex" class="value-input" :placeholder="langInfo.newLangInfo.registerPage.mandatory" />
        </div>
        <div class="item-tips">
          {{ phoneTips }}
        </div>
      </div>
      <!--Gender-->
      <div class="form-item">
        <div class="item-title">
          <img class="icon" style="width: 18px; height: 16px" src="../../assets/images/icons/sex.png" />
          <div class="text">
            {{ langInfo.newLangInfo.registerPage.gender }}
          </div>
        </div>
        <div class="item-select" @click="showSex = true">
          <div :class="[!formData.Sex ? 'select-placeholder' : 'select-value']">
            {{ !formData.Sex ? langInfo.newLangInfo.registerPage.mandatory : formData.Sex }}
          </div>
        </div>
        <div class="item-tips">
          {{ sexTips }}
        </div>
      </div>
      <!--Birthday-->
      <div class="form-item">
        <div class="item-title">
          <img class="icon" style="width: 18px; height: 16px" src="../../assets/images/icons/birth.png" />
          <div class="text">
            {{ langInfo.newLangInfo.registerPage.birth }}
          </div>
        </div>
        <div class="item-select" @click="showBirth = true">
          <div :class="[!formData.Date_of_Birth ? 'select-placeholder' : 'select-value']">
            {{ !formData.Date_of_Birth ? langInfo.newLangInfo.registerPage.mandatory : formData.Date_of_Birth }}
          </div>
        </div>
        <div class="item-tips">
          {{ birthTips }}
        </div>
      </div>
    </div>
    <div class="form-buttons">
      <div class="button-border" @click="$router.back()">
        {{ langInfo.newLangInfo.registerPage.last }}
      </div>
      <div class="button-blue" @click="handSubmit">
        {{ langInfo.newLangInfo.registerPage.next }}
      </div>
    </div>
    <!-- sex -->
    <van-popup v-model="showSex" position="bottom">
      <van-picker show-toolbar confirm-button-text="confirm" cancel-button-text="cancel" :columns="sexList" @cancel="showSex = false" @confirm="sexConfirm" />
    </van-popup>
    <!-- Data  -->
    <van-popup v-model="showBirth" position="bottom">
      <van-datetime-picker confirm-button-text="confirm" cancel-button-text="cancel" v-model="formData.Birth" type="date" @cancel="showBirth = false" :max-date="maxDate" :min-date="minDate" @confirm="birthConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { List } from "../../util/en_config_new";
import { langData } from "../../util/lang_config_new";
import { Popup, Picker, Toast, DatetimePicker, Dialog } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    return {
      showSex: false,
      showBirth: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2009, 11, 31),
      langInfo: null,
      showDataInfo: null, //包含手机号校验正则
      sexList: [],
      nameTips: "",
      emailTips: "",
      phoneTips: "",
      sexTips: "",
      birthTips: "",
      formData: {
        Email: "",
        Sex: "",
        Gender: "",
        Name: "",
        Phone: "",
        Phone_Number: "",
        Birth: new Date(1990, 0, 1),
        Date_of_Birth: "",
      },
    };
  },
  watch: {
    //性别
    showSex(val) {
      if (!val) {
        if (!this.formData.Gender) {
          this.sexTips = this.langInfo.newLangInfo.tipsInfo.gender;
        }
      } else {
        this.sexTips = "";
      }
    },
    //生日
    showBirth(val) {
      if (!val) {
        if (!this.formData.Date_of_Birth) {
          this.birthTips = this.langInfo.newLangInfo.tipsInfo.birth;
        }
      } else {
        this.birthTips = "";
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (localStorage.getItem("RegisterInfo")) {
        this.formData = JSON.parse(localStorage.getItem("RegisterInfo"));
      }
      if (localStorage.getItem("NationName")) {
        this.formData.Nation = localStorage.getItem("NationName");
        this.langInfo = this.setLang();
        if (this.formData.Date_of_Birth) {
          console.log(this.formData.Date_of_Birth, "111111111");
          this.formData.Birth = new Date(2009, 11, 31);
        }
      } else {
        this.$router.replace("/index");
      }
    },
    handSubmit() {
      console.log(this.langInfo);
      let msg = false;
      /**
       * 名字
       * */
      if (!this.formData.Name) {
        this.nameTips = this.langInfo.newLangInfo.registerPage.mandatory;
        //return;
        msg = true;
      }

      /**
       * 邮箱
       * */
      if (!this.formData.Email) {
        this.emailTips = this.langInfo.newLangInfo.registerPage.mandatory;
        //return;
        msg = true;
      }

      /**
       * 手机号
       * */
      if (!this.formData.Phone) {
        this.phoneTips = this.langInfo.newLangInfo.registerPage.mandatory;
        //return;
        msg = true;
      }

      /**
       * 性别
       * */
      if (!this.formData.Gender) {
        this.sexTips = this.langInfo.newLangInfo.registerPage.mandatory;
        //return;
        msg = true;
      }

      /**
       * 生日
       * */
      if (!this.formData.Date_of_Birth) {
        this.birthTips = this.langInfo.newLangInfo.registerPage.mandatory;
        //return;
        msg = true;
      }

      if (msg) {
        return;
      }

      if (this.formData.Email.length < 5) {
        this.emailTips = this.langInfo.newLangInfo.tipsInfo.email;
        return;
      }
      // const reg = /^([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
      const reg = /^([a-zA-Z0-9._-])+(([a-zA-Z0-9._-])|([._-][a-zA-Z0-9_])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
      if (!reg.test(this.formData.Email)) {
        this.emailTips = this.langInfo.newLangInfo.tipsInfo.email;
        return;
      }
      if (this.formData.Nation === "ID" && (this.formData.Phone.length < 9 || this.formData.Phone.length > 13)) {
        //ID 长度校验
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (this.formData.Nation === "TH" && this.formData.Phone.length != 9) {
        //TH 长度校验
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (this.formData.Nation === "VN" && (this.formData.Phone.length < 8 || this.formData.Phone.length > 10)) {
        //VN
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }

      if (this.formData.Nation === "SG" && this.formData.Phone.length != 8) {
        //VN
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }

      if (this.formData.Nation === "MY" && (this.formData.Phone.length < 9 || this.formData.Phone.length > 10)) {
        //MY
		console.log(22,this.formData.Phone,this.formData.Phone.length)
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }

      if (this.formData.Nation === "PH" && this.formData.Phone.length != 10) {
        //PH
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (!/^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/.test(this.formData.Phone)) {
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      this.formData.Phone_Number = this.showDataInfo.AreaCode + this.formData.Phone;

      this.isOnlyId();
    },
    sexConfirm(e, i) {
      this.formData.Sex = e.label;
      this.formData.Gender = this.sexList[i].value;
      this.showSex = false;
    },
    birthConfirm(e) {
      this.formData.Birth = e;
      this.formData.Date_of_Birth = this.formatTime(e);
      this.showBirth = false;
    },
    formatTime(data) {
      var date = new Date(data);
      return date.getFullYear() + "-" + (date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) + "-" + (date.getDate() >= 9 ? date.getDate() : "0" + date.getDate());
    },
    setLang() {
      let Info = langData.filter((res) => res.Nation == this.formData.Nation)[0];
      this.showDataInfo = List.filter((res) => res.Nation == this.formData.Nation)[0];
      this.sexList = Info.registration.sliginform.g_list.map((item) => {
        item.text = item.label;
        return item;
      });
      return Info == undefined ? langData[0] : Info;
    },
    nameRegex() {
      if (!this.formData.Name) {
        this.nameTips = this.langInfo.newLangInfo.registerPage.mandatory;
      }
    },
    emailRegex() {
      if (!this.formData.Email) {
        return;
      }
      // const reg = /^([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
      const reg = /^([a-zA-Z0-9._-])+(([a-zA-Z0-9._-])|([._-][a-zA-Z0-9_])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
      if (!reg.test(this.formData.Email)) {
        this.emailTips = this.langInfo.newLangInfo.tipsInfo.email;
        return;
      }
    },
    phoneRegex() {
      if (!this.formData.Phone) {
        return;
      }
      if (this.formData.Nation === "ID" && (this.formData.Phone.length < 9 || this.formData.Phone.length > 13)) {
        //ID 长度校验
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (this.formData.Nation === "TH" && this.formData.Phone.length != 9) {
        //TH 长度校验
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (this.formData.Nation === "VN" && (this.formData.Phone.length < 8 || this.formData.Phone.length > 10)) {
        //VN
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }

      if (this.formData.Nation === "SG" && this.formData.Phone.length != 8) {
        //VN
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }

      if (this.formData.Nation === "MY" && (this.formData.Phone.length < 9 || this.formData.Phone.length > 10)) {
        //MY
		console.log(11,this.formData.Phone,this.formData.Phone.length)
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (this.formData.Nation === "PH" && this.formData.Phone.length != 10) {
        //PH
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      if (!/^[+-]?\d*(\.\d*)?(e[+-]?\d+)?$/.test(this.formData.Phone)) {
        this.phoneTips = this.langInfo.newLangInfo.tipsInfo.phone;
        return;
      }
      // let veriphone = new RegExp(this.showDataInfo.phoneRegex, 'gi');
      // if (!veriphone.test(this.showDataInfo.AreaCode + this.formData.Phone)) {
      // 	this.phoneTips = this.langInfo.registration.sliginform.p_title_tip;
      // }
    },
    isOnlyId() {
      this.axios
        .post(`/api/info/?only_check=1`, this.formData)
        .then((res) => {
          let resstr = res.data;
          if (resstr.code === 200) {
            localStorage.setItem("RegisterInfo", JSON.stringify(this.formData));
            this.$router.push("/quest");
          } else {
            Toast(resstr.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.page-register {
  width: 100vw;
  height: 100vh;
  background: #4e5e9a;
  position: relative;

  .app-logo {
    padding: 54px 88px 54px;
    display: flex;
    justify-content: center;

    .logo {
      width: 200px;
      height: 60px;
    }
  }

  .app-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 44px;

    .progress-item {
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 50%;
      background: #ffffff;
      color: #4c4c4c;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
    }

    .progress-line {
      height: 1px;
      width: 80px;
      background: #ffffff;
    }

    .blue {
      background: #07cc00;
      color: #ffffff;
    }
  }

  .app-form {
    width: calc(100vw - 48px);
    height: 80vh;
    border-radius: 24px 24px 0px 0px;
    background: #ffffff;
    padding: 24px;

    .form-item {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;

      .item-title {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .icon {
          width: 18px;
          height: 18px;
        }

        .text {
          padding-left: 8px;
          color: #4d4d4d;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
        }
      }

      .item-value {
        //height: 32px;
        border-radius: 8px;
        overflow: hidden;
        background: #f8f8f8;
        padding: 0px 24px;

        .value-input {
          width: 100%;
          height: 32px;
          line-height: 32px;
          background: #f8f8f8;
          border: 0px;
        }

        .tips {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
          padding-right: 8px;
        }

        input {
          outline: none;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        input::placeholder {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #9fa0a2;
        }

        select {
          appearance: none;
          -webkit-appearance: none;
          border: 0;
          background: #f8f8f8;
          width: 100%;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .flex {
        padding-left: 6px;
        display: flex;
        align-items: center;
      }

      .item-tips {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: red;
      }

      .item-select {
        border-radius: 8px;
        overflow: hidden;
        background: #f8f8f8;
        padding: 0px 24px;
        display: flex;
        align-items: center;
        height: 32px;

        .select-value {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .select-placeholder {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #9fa0a2;
        }
      }
    }
  }

  .form-buttons {
    width: calc(100vw - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    padding: 18px 24px;
    background: #ffffff;
    z-index: 99;

    .button-border {
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      color: #ffffff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #292929;
      text-align: center;
      border-radius: 24px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
    }

    .button-blue {
      width: calc(100vw - 168px);
      height: 36px;
      line-height: 36px;
      background: #245bd9;
      color: #ffffff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      text-align: center;
      border-radius: 24px;
    }
  }
}
</style>
